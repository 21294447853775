import {
	createContext,
	createEffect,
	createMemo,
	createRoot,
	createSignal,
	getOwner,
	on,
	onCleanup,
	useContext,
	type Accessor,
	type ParentProps,
} from "solid-js"
import { AppCookieKey, AppCookies } from "#/lib/cookies"
// https://ru.wikipedia.org/wiki/%D0%9A%D0%BE%D0%B4%D1%8B_%D1%8F%D0%B7%D1%8B%D0%BA%D0%BE%D0%B2
export let languages = [
	"en",
	"ru",
] as const
export type Lang = (typeof languages)[number]
export let LANGS_META: Record<Lang, { emoji: string; name_eng: string }> = {
	en: { emoji: "🇬🇧", name_eng: "English" },
	ru: { emoji: "🇷🇺", name_eng: "Russian" },
}

function isSupportedLocale(locale: string): locale is Lang {
	return languages.includes(locale as Lang)
}

function getInitialLang(): Lang {
	if (isSupportedLocale(FORCE_LANGUAGE)) {
		return FORCE_LANGUAGE as any
	}
	let locale = AppCookies.get(AppCookieKey.Language)
	if (isSupportedLocale(locale)) {
		return locale
	}
	locale = navigator.language.slice(0, 2)
	if (isSupportedLocale(locale)) {
		return locale
	}
	// return 'ru'
	return "en"
}

type I18nContextType = {
	lang: Accessor<Lang>
	setLang: (lang: Lang) => void
}
let I18nContext = createContext<I18nContextType>({
	lang: () => "en" as const,
	setLang: () => { },
})

type Primitive = string | number | boolean
type LangFunction = (...args: Primitive[]) => string
interface Dict extends Record<string, string | LangFunction | Dict> { }
type LangDict = Partial<Record<Lang, Dict>>

type UnConst<T> = T extends string
	? string
	: T extends (...args: infer A) => unknown
	? T & { __length: A['length'] }
	: {
		[K in keyof T]: UnConst<T[K]>
	}

type RemoveLength<T> = T extends string
	? string
	: T extends (...args: infer A) => infer R
	? (...args: A) => R
	: {
		[K in keyof T]: RemoveLength<T[K]>
	}

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never

export let [{ lang, langs, setLang }, disposeLangRoot] = createRoot((d) => {
	let initial = getInitialLang()
	const [lang, setLang] = createSignal(initial)
	function langs<T extends LangDict>(dict: T & Record<keyof T, RemoveLength<UnionToIntersection<UnConst<T[keyof T]>>>>) {
		const handler = () => dict[lang()]
		if (getOwner()) return createMemo(handler)
		return handler
	}
	return [{ lang, setLang, langs }, d] as const
})


export function I18n(props: ParentProps<{}>) {
	createEffect(on(lang, () => {
		console.log("Locale saved to cookies")
		AppCookies.set(AppCookieKey.Language, lang())
	}, { defer: true }))

	onCleanup(disposeLangRoot)
	return <I18nContext.Provider value={{ lang, setLang }}>{props.children}</I18nContext.Provider>
}

export let useI18n = () => useContext(I18nContext)
