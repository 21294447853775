import { Show } from "solid-js"

import {
	drop, recompose,
	type ComponentLike, type ComposableComponentLike, type ComposableComponentProps
} from "#/lib/rx/mod"

type InputComponent = ComponentLike<"input"> | ComponentLike<"textarea">
type InputProps = {
	error?: string
	success?: boolean
	Input?: InputComponent
	Error?: ComponentLike<"div">
	class?: string
} & ComposableComponentProps<InputComponent>

export function BasicInput(props: InputProps) {
	let { composed, uncomposed } = recompose(props, "error", "success", "Input", "Error", "classList", "class")

	let {
		Error = BasicInput.Error,
		Input = p => <input {...p} />,
	} = uncomposed

	return [
		<Input
			{...composed}
			classList={{
				":c: w-[calc(100%-2px)] h-12 rounded-2 p-inline-3 border-none resize-none outline-(1px solid offset-0) m-inline-1px": true,
				":c: dark:(bg-gray-800 c-white outline-gray-700/70) light:(bg-gray-000 c-black-999 outline-gray-200)": true,
				":c: disabled:(dark:bg-gray-800/30 light:bg-gray-000/30 dark:c-gray-400 light:c-gray-600) focus-outline-blue-500": true,
				"ul-1:outline-red-500": !!uncomposed.error,
				"ul-1:outline-green-5": uncomposed.success,
				[uncomposed.class]: uncomposed.class != null,
				...uncomposed.classList,
			}}
		/>,
		<Show when={props.error} children={<Error error={props.error} />} />,
	]
}

BasicInput.Root = function(props: ComposableComponentProps<"div"> & { class?: string }) {
	let other = drop(props, "class", "classList")
	return <div {...other} classList={{ ...props.classList, [props.class]: !!props.class, "relative": true }} />
}

type InputErrorProps = {
	error?: string
} & ComposableComponentProps<"div">

BasicInput.Error = (props: InputErrorProps) => (
	<div
		{...drop(props, "classList")}
		classList={{ ...props.classList, ":c: c-red-500 font-500 text-3 mt2 contain-none": true }}
		innerText={props.error}
	/>
)

type IconWProps = {
	right?: boolean
	x?: number

	wrapperc?: string
	Wrapper?: ComponentLike<"div">

	iconc?: string
	Icon?: ComposableComponentLike<"i">
} & ComposableComponentProps<ComponentLike<"div">>

BasicInput.IconW = function(props: IconWProps) {
	let { composed, uncomposed } = recompose(props, "x", "right", "wrapperc", "Wrapper", "iconc", "Icon", "classList")

	let {
		Wrapper = p => <div {...p} />,
		Icon = p => <i {...p} />,
	} = uncomposed

	return (
		<Wrapper
			{...composed}
			classList={{
				"h-full w7 abs-centered-y": true,
				...uncomposed.classList,
				[uncomposed.wrapperc]: !!uncomposed.wrapperc,
			}}
			style={{
				[uncomposed.right ? "right" : "left"]: `calc(8px + ${uncomposed.x ?? 0}*30px)`,
			}}
			children={<Icon classList={{ ":c: size-6 abs-centered dark:c-gray-400 light:c-gray-300": true, [uncomposed.iconc]: !!uncomposed.iconc }} />}

		/>
	)
}

type WordCounterProps = {
	max: number
	current: number
	class: string
} & ComposableComponentProps<"span">

BasicInput.WordCounter = function(props: WordCounterProps) {
	let { composed, uncomposed } = recompose(props, "max", "current", "class", "classList")

	return (
		<Show when={uncomposed.max - uncomposed.current < 20}>
			<span
				innerText={uncomposed.max - uncomposed.current}
				{...composed}
				classList={{
					...uncomposed.classList,
					"text-3 fw-semibold": true,
					"c-red-400": uncomposed.max - uncomposed.current < 12,
					"c-red-500": uncomposed.current > uncomposed.max,
					[props.class]: props.class != null
				}}
			/>
		</Show>
	)
}
